/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n from '../../config/_i18n';

const qs = require('qs');

const withdrawal = {
  state: () => ({
    selectedCurrency: null,
    wallet: [],
    history: [],
    next: '',
    banks: [],
    isLoading: true,
    series: [],
    cryptoPrice: 0,
    withdrawalBalance: 0,
    withdrawalBalanceNotFormatted: 0,
    amount: null,
    selectedBank: '',
    selectedBankCurrency: null,
    showAddBankAccount: false,
    selectedWalletAddress: [],
    transfer: { limits: {} },
    isCrypto: false,
    isTransfer: false,
    showAddWalletModal: false,
    add: {
      provider: '',
      currency: '',
      address: '',
      bank: '',
      label: '',
      tag: '',
      imageUrl: '',
      
    },
    networks: [],
    selectedNetwork: null
  }),
  mutations: {
    SET_BALANCE(state, payload) {
      state.withdrawalBalance = payload.data.available_formatted;
      const stringPrice = payload.data.available_formatted.replace(/[^\d.]/g, '');
      const priceFloat = parseFloat(stringPrice);
      state.withdrawalBalanceNotFormatted = priceFloat;
    },
    SET_WITHDRAWAL(state, payload) {
      for (let i = 0; i < payload.data.length; i++) {
        if (parseInt(payload.data[i].amlScore) <= parseInt(60)) {
          state.selectedWalletAddress.push(payload.data[i]);
        }
      }
      state.wallet = payload.data;
    },
    SET_BUY_NOW_PRICES(state, payload) {
      state.cryptoPrice = payload.data.price_buy / 100;
    },
    SET_PROVIDERS(state) {
      if (state.isCrypto === true) {
        //state.transfer = payload.data.crypto_currency;
        if (state.selectedBank.fee_type === 'ratio') {
          state.transfer.limits.fee = state.amount * state.selectedBank.fee;
        } else {
          state.transfer.limits.fee = state.selectedBank.fee;
        }
        state.isTransfer = true;
      } else {
        state.transfer.limits.fee = 0;
        if (state.amount < state.selectedBank.min_pay) {
          Vue.$toast.error(i18n.t('withdrawalMinLimit') + ' ' + state.selectedBank.min_pay_formatted);
          return;
        }
        state.isTransfer = true;
      }
    },
    SET_ADD_WALLET(state) {
      state.showAddWalletModal = false;
      // state.showAddBankAccount = false;
    },
    SET_CREATE_WITHDRAWAL(state) {
      state.isTransfer = false;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BANKS(state, payload) {
      if (state.banks.length > 0) return;
      for (let key in payload.data) {
        state.banks.push(payload.data[key]);
      }
    },
    CLEAR_BALANCE(state) {
      state.wallet = [];
      state.selectedWalletAddress = [];
    },
    CLEAR_WALLET_FORM(state) {
      state.add = {
        provider: '',
        currency: '',
        address: '',
        bank: '',
        label: '',
        tag: '',
        imageUrl: '',

      };
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
    },
    updateIsCrypto(state, value) {
      state.isCrypto = value;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
      state.isCrypto = value.crypto;
      if(value?.code && value.code.toLowerCase() === 'usdt') {
        state.networks = value?.networks || [];
        state.selectedNetwork = value.networks?.[0] || null;
      }
    },
    showAddWalletModalUpdate(state, playload) {
      state.showAddWalletModal = playload;
    },
    showAddBankAccountUpdate(state, playload) {
      state.showAddBankAccount = playload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    updateSelectedNetwork(state, payload) {
      state.selectedNetwork = payload;
    },
    SET_BANK_IMAGE_WITHDRAW(state, payload){
      state.bankImageWithdraw = payload;

    }
  },
  actions: {
    GET_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then(res => {
          commit('SET_BALANCE', res.data);
          this.dispatch('wallet/GET_BALANCE', { root: true });
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_BANKS({ commit }) {
      Services.get(API.banks)
        .then(res => {
          commit('SET_BANKS', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_WITHDRAWAL({ commit, state }, currency) {
      let url = `/bank_transfer/${currency.code.toLowerCase()}/null`;
      if (currency.crypto) {
        const assetId = currency.code.toLowerCase() === 'usdt' ? state.selectedNetwork.id : 'null';
        url = `/crypto_currency/${currency.code.toLowerCase()}/${assetId}`;
      }
      
      Services.get(`${API.withdraw}${url}`)
        .then(res => {
          if (res.data.data.length !== 0) {
            commit('CLEAR_BALANCE');
            commit('SET_WITHDRAWAL', res.data);
          } else {
            commit('CLEAR_BALANCE');
          }
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    
    GET_BUY_NOW_PRICES({ dispatch, commit }, currency) {
      Services.get(API.buyAndSellNowPrices + currency)
        .then(res => {
          
          commit('SET_BUY_NOW_PRICES', res.data);
          dispatch('GET_PROVIDERS', currency);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_PROVIDERS({ commit }) {
      commit('SET_PROVIDERS');
    },

    async UPLOAD_DOCUMENT_WITHDRAW({ commit, state, dispatch }, params) {
      dispatch('overlayLoader/showLoader', null, { root: true });
      try {
        let address;
        if (state.isCrypto) {
          address = state.add.address;
        } else {
          address = state.add.address.trim().replace(/ +/g, '');
          let addressPrefix = state.add.bank.country.code;
          if (state.add.bank.type === 'UBAN') addressPrefix = 'ct';
          address = addressPrefix + address;
        }
    
        let request = {
          provider: !state.isCrypto ? 'bank_transfer' : 'crypto_currency',
          currency: !state.isCrypto ? state.add.currency.code : state.selectedCurrency.code,
          address: address,
          bank: state.add.bank.id,
          label: state.add.label,
          tag: state.add.tag,
        };

        if(state.selectedCurrency.code === 'USDT') {
          request.network = state.selectedNetwork.asset_id;
        }
    
        let walletTag = state.add.tag.trim();
        if (walletTag !== '') {
          request.tag = walletTag;
        }

        let requestNew = qs.stringify(request);
        requestNew = requestNew + '&imageUrl=' + state.bankImageWithdraw;
    
        // Hit createWallet API first
        const createWalletResponse = await Services.post(API.createWallet, requestNew);
        if ( createWalletResponse.status === 200) {
          commit('SET_ADD_WALLET', createWalletResponse.data);
          Vue.$toast.success("Crypto wallet request received. It will be reviewed within 24 hours");
    
          // Save the image after wallet creation
          let data = new FormData();
          data.append('file', params.file);
          data.append('uid', params.id);
          data.append('module', 'walletproof');
    
          const headers = {
            'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY,
          };
    
          const uploadDocumentResponse = await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/uploadDocument', data, { headers });
    
          if (uploadDocumentResponse.status === 200) {

            const updateRequest = {
              wallet_id:createWalletResponse.data.data.id,
              imageURL: uploadDocumentResponse.data.message.pathIs,
            };
            const updateResponse = await Services.post(API.updateImageUrl, updateRequest);

            if (updateResponse.status === 200){
              dispatch('GET_WITHDRAWAL', state.selectedCurrency);
              commit('CLEAR_WALLET_FORM');
              commit('IS_LOADING', false);
              this.$refs.form.reset();
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          Vue.$toast.error(error.response.data.message);
        }
      } finally {
        dispatch('overlayLoader/hideLoader', null, { root: true });
      }
    },
    POST_CREATE_WITHDRAWAL({ state, dispatch, commit }) {
      let formData;
      if (state.isCrypto) {
        formData = {
          wallet_code: state.selectedBank.code,
          amount: state.amount
        };
      } else {
        formData = {
          wallet_code: state.selectedBank.wallet_code,
          amount: state.amount
        };
      }

      if (state.selectedCurrency.code === 'USDT') {
        formData.network = state.selectedNetwork.asset_id
      }

      Services.post(API.withdrawCreate, formData)
        .then(res => {
          var amount = state.amount;
          var coin = state.selectedCurrency.code;
          var type = 'withdraw';

          let data = {
            amount: amount,
            coin: coin,
            type: type
          };
          const headers = {
            'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
          };
          Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/updateWallet', data, { headers })
            .then(() => {
              commit('SET_CREATE_WITHDRAWAL', res.data);
              Vue.$toast.success(res.data.message);
              dispatch('GET_HISTORY', {
                currency: state.selectedCurrency.code.toLowerCase(),
                isNextPage: false
              });
              dispatch('GET_BALANCE', state.selectedCurrency.code);
              commit('IS_LOADING', false);
            })
            .catch(err => {
              if (err?.response?.data?.message) {
                Vue.$toast.error(err.response.data.message);
              }
            });
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    POST_ADD_WALLET({ state, dispatch, commit }) {
   
      
      let address;
      let request;
      
      if (state.isCrypto) {
        address = state.add.address;
      } else {
        address = state.add.address.trim().replace(/ +/g, '');
        let addressPrefix = state.add.bank.country.code;
        if (state.add.bank.type === 'UBAN') addressPrefix = 'ct';
        address = addressPrefix + address;
      }
    
      if (state.selectedCurrency.code === 'USDT') {
        request = {
          provider: !state.isCrypto ? 'bank_transfer' : 'crypto_currency',
          currency: !state.isCrypto ? state.add.currency.code : state.selectedCurrency.code,
          address: address,
          bank: state.add.bank.id,
          label: state.add.label,
          tag: state.add.tag,
          network: state.selectedNetwork.asset_id
        };
      } else {
        request = {
          provider: !state.isCrypto ? 'bank_transfer' : 'crypto_currency',
          currency: !state.isCrypto ? state.add.currency.code : state.selectedCurrency.code,
          address: address,
          bank: state.add.bank.id,
          label: state.add.label,
          tag: state.add.tag
        };
      }

      let walletTag = state.add.tag.trim();
      if (walletTag !== '') {
        request.tag = walletTag;
      }

      let requestNew = qs.stringify(request);
      requestNew = requestNew + '&imageUrl=' + state.add.imageUrl;
 
      Services.post(API.createWallet, requestNew)
        .then(res => {
          commit('SET_ADD_WALLET', res.data);
          Vue.$toast.success("Crypto wallet request received. It will be reviewed within 24 hours");
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('CLEAR_WALLET_FORM');
          commit('IS_LOADING', false);
          // Reset the form using Vue's built-in ref
          this.$refs.form.reset();
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    DELETE_BANK({ state, dispatch, commit }, bankCode) {
      Services.delete(API.withdraw + '/' + bankCode)
        .then(res => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_CLEAR_BALANCE({ commit }) {
      commit('CLEAR_BALANCE');
    },
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.withdrawHistory}${params.currency}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${params.currency}`;
      }
      Services.get(nextPage)
        .then(res => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  },
  getters: {},
  namespaced: true
};

export default withdrawal;
